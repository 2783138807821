// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.statistics-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 200px;
  width: 1525px;
  padding-top: 40px;
  /*padding-left: 500px;*/
  /*padding-right: 500px;*/
}

.statistics-container > .statistic > p {
  font-size: 18px;
}

.statistics-container > .statistic > h4 {
  font-size: 22px;
}

.statistic {
  /*text-align: center;*/
  vertical-align: bottom;
  flex-basis: 15%;
  /*padding-right: 200px;*/
  height: 150px;
}

.iphones {
  font-weight: bold; /* Makes the text bold */
  font-size: 1em; /* Increases the font size */
}

.title {
  margin-top: 30px;
  margin-left: -1280px;
  color: #a1a1a1;
  position: absolute;
  font-size: 18px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/miscStats/index.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,iBAAiB;EACjB,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,eAAe;EACf,wBAAwB;EACxB,aAAa;AACf;;AAEA;EACE,iBAAiB,EAAE,wBAAwB;EAC3C,cAAc,EAAE,4BAA4B;AAC9C;;AAEA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":["\n.statistics-container {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  height: 200px;\n  width: 1525px;\n  padding-top: 40px;\n  /*padding-left: 500px;*/\n  /*padding-right: 500px;*/\n}\n\n.statistics-container > .statistic > p {\n  font-size: 18px;\n}\n\n.statistics-container > .statistic > h4 {\n  font-size: 22px;\n}\n\n.statistic {\n  /*text-align: center;*/\n  vertical-align: bottom;\n  flex-basis: 15%;\n  /*padding-right: 200px;*/\n  height: 150px;\n}\n\n.iphones {\n  font-weight: bold; /* Makes the text bold */\n  font-size: 1em; /* Increases the font size */\n}\n\n.title {\n  margin-top: 30px;\n  margin-left: -1280px;\n  color: #a1a1a1;\n  position: absolute;\n  font-size: 18px;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
