import React, {useContext, useEffect} from 'react';
import './index.css';
import {bluePoint, Point, purplePoint, yellowPoint} from "./energyPoint";
import {FlowType, IFlowData} from "../../models/flow";
import {EnergyLines} from "./energyLine";
import {EnergyFlowCore} from "../../services/energyFlowCore";
import DataContext from "../../dataContext";

export interface PointPosition {
  x: number;
  y: number;
}


export const EnergyFlow: React.FC = () => {
  const refreshMin = useContext(DataContext) || 5;
  const [flowData, setFlowData] = React.useState<IFlowData>({
    flowType: FlowType.overConsumption,
    pv: 0,
    load: 0,
    grid: 0,
  });

  const refreshTimeInMs = refreshMin * 60000;

  useEffect(() => {
    const interval = setInterval(async () => {
      setFlowData(await EnergyFlowCore.getNewFlowData(refreshMin));
      console.log("Refreshed")
    }, refreshTimeInMs);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    (async () => {
      setFlowData(await EnergyFlowCore.getNewFlowData(refreshMin));
    })();
  }, []);

  const pvPoint: PointPosition = {x: 275, y: 100};
  const loadPoint: PointPosition = {x: 100, y: 430};
  const gridPoint: PointPosition = {x: 450, y: 430};

  return (
    <div className="node position-relative"
         style={{height: '675px', top: '0px', left: '-35px'}}>
      <div style={{position: 'absolute', top: '450px', left: '0px'}}>
        {/*<h3 style={{position: 'absolute', top: '-450px', left: '170px', width: "200px"}}>Energy Flow</h3>*/}
        <div className="line-holder" style={{position: 'absolute', bottom: '500px', left: '0px'}}>
          <svg width="500" height="600" style={{position: 'absolute', top: '50px', left: '0'}} viewBox='0 0 500 500'>
            <EnergyLines flow={flowData.flowType} pvPoint={pvPoint} loadPoint={loadPoint} gridPoint={gridPoint}/>
          </svg>
        </div>

        <div className="line-holder" style={{position: 'absolute', bottom: '0px', left: '25px'}}>
          <div className="point-holder" style={{position: 'absolute', top: '-400px', left: '150px'}}>
            <Point label="PV Anlage" value={flowData.pv} style={yellowPoint} icon="icons/solar-panel-solid.svg"/>
          </div>
          <div className="point-holder" style={{position: 'absolute', top: '-110px', left: '50px'}}>
            <Point label="Stromverbrauch" value={flowData.load} style={bluePoint} icon="icons/electrical_services.svg"/>
            <Point label="Stromnetz" value={flowData.grid} style={purplePoint} icon='icons/electric_meter.svg'/></div>
        </div>
      </div>
    </div>
  );
};
