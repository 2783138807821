import axios from 'axios';
import {FlowType, IFlowData} from "../../models/flow";
import {IApiData} from "../../models/daily";
import {
  config_autarky,
  config_daily,
  config_daily_sum,
  config_efficiency,
  config_grid,
  config_panel
} from "../../models/requests";

export class DataFetcher {
  public static async fetchNewFlowData(interval: number): Promise<IFlowData> {
    let flowData: IFlowData = {
      pv: 0,
      grid: 0,
      load: 0,
      flowType: FlowType.undefined
    };

    try {
      const responseGrid = await axios.request<IApiData[]>(config_grid(interval));
      if (responseGrid.data[0]) {
        flowData.grid = parseInt(responseGrid.data[0]._value);
      }

      const responsePanel = await axios.request<IApiData[]>(config_panel(interval));
      if (responsePanel.data[0]) {
        flowData.pv = parseInt(responsePanel.data[0]._value);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }

    return flowData;
  }

  public static async fetchDailyData(): Promise<number[]> {
    let data: number[] = [];
    try {
      const response = await axios.request<IApiData[]>(config_daily);
      console.log("Length of daily data: " + response.data.length)
      for (let i = 0; i < response.data.length; i++) {
        data[i] = parseInt(response.data[i]._value);
      }
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public static async fetchDailDataSum(): Promise<number> {
    try {
      const response = await axios.request<IApiData[]>(config_daily_sum);
      return parseInt(response.data[0]._value);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public static async fetchAutarkyData(): Promise<number> {
    try {
      const response = await axios.request<IApiData>(config_autarky);
      return parseInt(response.data._value);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public static async fetchEfficiencyData(): Promise<number> {
    try {
      const response = await axios.request<IApiData>(config_efficiency);
      return parseInt(response.data._value);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}


export default DataFetcher;