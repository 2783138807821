// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  text-align: start;
  padding-left: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /*display everything in row*/
  display: flex;
}

.header > h1 {
  margin-top: 2%;
  margin-left: 1%;
}

.footer {
  bottom: 0;
  width: 100%;
}

.dashboard {
  width: 100%;
  height: 100%;
  margin-top: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.logo {
  width: 100%;
  height: 100%;
  max-width: 7.1%;
  margin-top: 35px;
  margin-left: 12px;
  margin-bottom: 35px;
}

.stats {
  padding-right: 50px;
  margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/index.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,uCAAuC;EACvC,4BAA4B;EAC5B,aAAa;AACf;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,SAAS;EACT,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".header {\n  text-align: start;\n  padding-left: 30px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n  /*display everything in row*/\n  display: flex;\n}\n\n.header > h1 {\n  margin-top: 2%;\n  margin-left: 1%;\n}\n\n.footer {\n  bottom: 0;\n  width: 100%;\n}\n\n.dashboard {\n  width: 100%;\n  height: 100%;\n  margin-top: 60px;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n}\n\n.logo {\n  width: 100%;\n  height: 100%;\n  max-width: 7.1%;\n  margin-top: 35px;\n  margin-left: 12px;\n  margin-bottom: 35px;\n}\n\n.stats {\n  padding-right: 50px;\n  margin-top: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
