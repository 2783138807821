import React, {useEffect, useState} from 'react';
import "./index.css"
import {EnergyFlowCore} from "../../services/energyFlowCore";
import DataFetcher from "../../services/energyFlowCore/dataFetcher";
import {BarData, getDailyProduction} from "../../services/production";


const MiscStats: React.FC = () => {
  const [selfConsumptionRate, setSelfConsumptionRate] = useState<number>(0);
  const [production, setProduction] = useState<number>(0);
  const [iphonesCharged, setIphonesCharged] = useState<number>(0);


  async function updateStats() {
    setSelfConsumptionRate(await DataFetcher.fetchEfficiencyData());

    const dailyData = await DataFetcher.fetchDailDataSum() / 1000;

    const productionInMWh = Math.round(dailyData / 1000);
    setProduction(productionInMWh);
    // const co2Reduction = ((dailyData * 0.846) / 2240);
    const iphonesCharged = Math.round((dailyData / 0.0127));
    console.log("Tägliche Produktion: " + dailyData)
    console.log("CO2 Reduktion: " + iphonesCharged);
    setIphonesCharged(iphonesCharged);
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      await updateStats();
    }, 180000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    (async () => {
      await updateStats();
    })();
  }, []);

  const iphonesChargedWithComas = iphonesCharged.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  return (
    <div className="w-auto statistic node misc-stats">
      <div className="statistics-container">
        <div className="statistic">
          <h4>Eigenverbrauchsquote</h4>
          <p>{selfConsumptionRate}%</p>
        </div>
        <div className="statistic">
          <h4>PV Produktion</h4>
          <p>{production} MWh</p>
        </div>
        <div className="statistic">
          <h4>Mit dieser Energie könnten Sie <span className="iphones">{iphonesChargedWithComas}</span> iPhones laden
          </h4>
        </div>
        <p className="title">Monatliche Statistiken</p>
      </div>
    </div>
  );
};

export default MiscStats;
