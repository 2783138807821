import DataFetcher from "../energyFlowCore/dataFetcher";

export interface BarData {
  name: string;
  kWh: number;
}

const getDays = (year: number, month: number) => {
  console.log(new Date(year, month, 0).getDate())
  return new Date(year, month, 0).getDate();
};

const getMonthData = async (): Promise<number[]> => {
  const monthData: number[] = [];
  const dailyProduction = await DataFetcher.fetchDailyData();
  console.log("dailyProduction", dailyProduction.length);
  for (let i = 0; i <= dailyProduction.length + 3; i++) {
    monthData.push(dailyProduction[i]);
  }
  return monthData;
}

export async function getDailyProduction() {
  const monthValues = await getMonthData();
  console.log("monthValues", monthValues);
  const newMonthData: BarData[] = [];
  for (let i = 0; i < 30; i++) {
    let name = "";
    let lastMonthStopPoint = getDays(new Date().getFullYear(), new Date().getMonth()) - new Date().getDate();
    if (i > lastMonthStopPoint) {
      name = `${(i) - lastMonthStopPoint}.${new Date().getMonth() + 1}`;
    } else {
      name = `${new Date().getDate() + (i)}.${new Date().getMonth()}`
    }
    if (i % 2 !== 0) {
      name = "";
    }
    if (i >= monthValues.length) {
      newMonthData.push({name: name, kWh: 0});
      continue;
    }
    const value = monthValues[i];
    newMonthData.push({name, kWh: value / 1000});
  }
  return newMonthData;
}
