import {AxiosRequestConfig} from "axios";
import {useContext} from "react";
import DataContext from "../../dataContext";

// const interval = useContext(DataContext) || 5;
const apiAddress = (): string => {
  if (window.location.hostname === `localhost`) {
    return "http://localhost:3001";
  } else {
    return "";
  }
}

export const config_grid = (interval: number): AxiosRequestConfig => {
  return {
    method: `get`,
    maxBodyLength: Infinity,
    url: `${apiAddress()}/api/grid`,
    headers: {},
    params: {
      interval: interval
    }
  }
};

export const config_panel = (interval: number): AxiosRequestConfig => {
  return {
    method: `get`,
    maxBodyLength: Infinity,
    url: `${apiAddress()}/api/panel`,
    headers: {},
    params: {
      interval: interval
    }
  };
}

export const config_daily: AxiosRequestConfig = {
  method: `get`,
  maxBodyLength: Infinity,
  url: `${apiAddress()}/api/dailies`,
  headers: {}
};

export const config_daily_sum: AxiosRequestConfig = {
  method: `get`,
  maxBodyLength: Infinity,
  url: `${apiAddress()}/api/dailies/sum`,
  headers: {}
};

export const config_efficiency: AxiosRequestConfig = {
  method: `get`,
  maxBodyLength: Infinity,
  url: `${apiAddress()}/api/efficiency`,
  headers: {}
};

export const config_autarky: AxiosRequestConfig = {
  method: `get`,
  maxBodyLength: Infinity,
  url: `${apiAddress()}/api/autarky`,
  headers: {}
};