import React from "react";
import './index.css';
import MiscStats from "../miscStats";
import {EnergyFlow} from "../energyFlow";
import ProductionChart from "../production";
import {config_grid} from "../../models/requests";

const Dashboard: React.FC = () => {
  console.log(config_grid)
  return (
    <div>
      <div className="header">
        <img src="https://www.aepli.ch/wp-content/uploads/2023/11/LOGO-AEPLI.svg" alt="Logo" className="logo"/>
        <h1>Photovoltaikanlage</h1>
      </div>
      <div>
        <div className="dashboard container-fluid">
          <EnergyFlow/>
          <ProductionChart/>
        </div>
        <div className="dashboard container-fluid stats">
          <MiscStats/>
        </div>
      </div>
      <div className="footer">
      </div>
    </div>
  );
}
export default Dashboard;
