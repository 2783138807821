// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Bree+Serif);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://db.onlinewebfonts.com/c/6f6cbe877ab89b84810628a5a5f892d0?family=NeueHaasGroteskDisp+Pro);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "NeueHaasGroteskDisp Pro";
  font-weight: 150;
  line-height: 1.2;
}

.App {
  text-align: center;
}

.node {
  width: 550px;
  background: #f5f5f5;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
}


.App-link {
  color: #61dafb;
}

p, text, h1, h2, h3, h4, h5, h6 {
  /*font-family: "NeueHaasGroteskDisp Pro", serif;*/
  /*color: #0256ff;*/
  /*text-align: center;*/
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAIA;EACE,sCAAsC;EACtC,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,2CAA2C;EAC3C,mBAAmB;AACrB;;;AAGA;EACE,cAAc;AAChB;;AAEA;EACE,iDAAiD;EACjD,kBAAkB;EAClB,sBAAsB;AACxB","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Bree+Serif');\n@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');\n@import url(https://db.onlinewebfonts.com/c/6f6cbe877ab89b84810628a5a5f892d0?family=NeueHaasGroteskDisp+Pro);\n\n* {\n  font-family: \"NeueHaasGroteskDisp Pro\";\n  font-weight: 150;\n  line-height: 1.2;\n}\n\n.App {\n  text-align: center;\n}\n\n.node {\n  width: 550px;\n  background: #f5f5f5;\n  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);\n  border-radius: 40px;\n}\n\n\n.App-link {\n  color: #61dafb;\n}\n\np, text, h1, h2, h3, h4, h5, h6 {\n  /*font-family: \"NeueHaasGroteskDisp Pro\", serif;*/\n  /*color: #0256ff;*/\n  /*text-align: center;*/\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
