// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart {
  width: 25%;
  height: 40%;

  margin: 0 10%;
}

.production-chart {
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/production/index.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;;EAEX,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":[".chart {\n  width: 25%;\n  height: 40%;\n\n  margin: 0 10%;\n}\n\n.production-chart {\n  padding: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
