import React from 'react';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Dashboard from "./components/dashboard";
import DataContext from "./dataContext";

function App() {
  const queryParams = new URLSearchParams(window.location.search);

  // Get the value of the 'exampleParam' parameter
  const refreshMin = parseInt(queryParams.get('refreshMin') || '5');

  return (
    <DataContext.Provider value={refreshMin}>
      <div className="App">
        <Dashboard></Dashboard>
      </div>
    </DataContext.Provider>
  );
}

export default App;
